import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Eine Bewerbung prüfen",
  "description": "Sie wurden eingeladen eine Bewerbung im Karriereportal Ihres Unternehmens zu prüfen. So gehen Sie vor.",
  "author": "Anna-Mai Petersen",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Zum ersten Mal anmelden`}</h2>
    <p>{`Wenn Sie sich zum ersten Mal anmelden, erhalten Sie ein E-Mail mit einem Einladungslink.`}</p>
    <img src="/images/gettingstarted/pruefen01.jpg" alt="bewerbung prüfen" style={{
      "width": "100%"
    }} className="image" />
    <p>{`Bitte klicken Sie auf den Link "Einladung annehmen" in der E-Mail und vergeben Sie im sich öffnenden Fenster ein sicheres Passwort* für Ihren Karriereportal-Zugang und klicken Sie auf "Bestätigen".`}</p>
    <img src="/images/gettingstarted/pruefen02.jpg" alt="bewerbung prüfen" style={{
      "width": "100%"
    }} className="image" />
    <p>{`Im Anschluss können Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort anmelden. Sie gelangen auf die Management-Startseite des Karriereportals.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/Passwoerter/passwoerter_node.html"
      }}>{`so legen Sie ein sicheres Passwort fest`}</a></p>
    <h2>{`Stellenanzeige wählen`}</h2>
    <p>{`Auf der Startseite können Sie nun den Menüpunkt "Jobs" auswählen um die für Sie freigeschalteten Stellenanzeigen einzublenden.`}</p>
    <img src="/images/gettingstarted/pruefen03.jpg" alt="bewerbung prüfen" style={{
      "width": "100%"
    }} className="image" />
    <p>{`Klicken Sie nun auf die Stelle für die Sie sich interessieren.`}</p>
    <img src="/images/gettingstarted/pruefen04.jpg" alt="bewerbung prüfen" style={{
      "width": "50%"
    }} className="image" />
    <h2>{`Bewerbung auswählen`}</h2>
    <p>{`Nun sehen Sie die für diese Stelle eingegangenen Bewerbungen. Wählen Sie die für Sie interessante Bewerbung mit einem Klick auf "Anzeigen" aus.`}</p>
    <img src="/images/gettingstarted/pruefen05.png" alt="bewerbung prüfen" style={{
      "width": "100%"
    }} className="image" />
    <p>{`In der Bewerberansicht können Sie die Stammdaten der Bewerbung einsehen, die übermittelten Unterlagen und weitere von der BewerberIn angegebene Informationen sehen.`}</p>
    <h2>{`Bewerbung einstufen`}</h2>
    <p>{`Sie haben nun die Möglichkeit die Bewerbung einzustufen.`}</p>
    <p>{`Bewerten Sie Ihren Ersteindruck per Klick im Bereich Rating.`}</p>
    <img src="/images/gettingstarted/pruefen06.png" alt="bewerbung prüfen" style={{
      "width": "50%"
    }} className="image" />
    <p>{`Fügen Sie Kommentare hinzu um Ihren Eindruck für Ihre Kollegen zu dokumentieren.`}</p>
    <img src="/images/gettingstarted/pruefen07.png" alt="bewerbung prüfen" style={{
      "width": "50%"
    }} className="image" />
    <p>{`Ordnen Sie die Bewerbung einem Pool (Bearbeitungs-Status) zu. Diese werden vom HR-Team individuell festgelegt. In unserem Beispiel können Sie zwischen "Interessant" und "Absagen" auswählen.`}</p>
    <img src="/images/gettingstarted/pruefen08.png" alt="bewerbung prüfen" style={{
      "width": "50%"
    }} className="image" />
    <h2>{`Bewerbung weiterleiten`}</h2>
    <p>{`Um einem bereits berechtigten Kollegen auf einen einzelnen Bewerber hinzuweisen ohne die Unterlagen als Anhang zu verschicken klicken Sie auf Weiterleiten. Es öffnet sich ein E-Mail-Fenster in Ihrem Standard E-Mail-Programm. Geben Sie hier die Empfänger E-Mail-Adresse an und versenden Sie die E-Mail ggf. mit Kommentar. Der Empfänger kann mit Klick auf den Link in der E-Mail auf den Bewerber zugreifen, sofern er/sie einen Zugang zum Portal hat und berechtigt ist den Bewerber zu sehen. `}{`[ähnlich der Notiz an einem Datensatz in NAV]`}</p>
    <img src="/images/gettingstarted/pruefen09.png" alt="bewerbung prüfen" style={{
      "width": "30%"
    }} className="image" />
    <h2>{`Bewerber Per E-Mail Kontaktieren`}</h2>
    <p>{`Wenn Sie einen Bewerber per E-Mail kontaktieren möchten, klicken Sie auf den Button E-Mail senden. Es öffnet sich ein E-Mail-Fenster in Ihrem Standard E-Mail-Programm. Formulieren Sie hier Ihre Nachricht.`}</p>
    <img src="/images/gettingstarted/pruefen10.png" alt="bewerbung prüfen" style={{
      "width": "30%"
    }} className="image" />
    <h2>{`Bewerber einladen`}</h2>
    <img src="/images/gettingstarted/pruefen11.png" alt="bewerbung prüfen" style={{
      "width": "100%"
    }} className="image" />
    <p>{`Wenn Sie einen Bewerber zum Vorstellungsgespräch einladen möchten, klicken Sie auf den Button Vorstellungsgespräch planen. Es öffnet sich ein E-Mail-Fenster in Ihrem Standard E-Mail-Programm. Formulieren Sie hier Terminvorschläge und ggf. ein individuelles Anschreiben. Es können Vorlagen hinterlegt werden.
Zur Bestätigung des Termins nutzen Sie den Button Vorstellungsgespräch bestätigen.`}</p>
    <h2>{`Bewerber absagen`}</h2>
    <img src="/images/gettingstarted/pruefen12.png" alt="bewerbung prüfen" style={{
      "width": "30%"
    }} className="image" />
    <p>{`Wenn Sie einen Bewerber absagen möchten, klicken Sie auf den Button Absagen. Es öffnet sich ein E-Mail-Fenster in Ihrem Standard E-Mail-Programm. Formulieren Sie hier den Absagegrund und ggf. ein individuelles Anschreiben. Es können Vorlagen hinterlegt werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      